import types from "../types";
import {
  addMarkupPriceAPI,
  sendAddedMarkupTicketAPI
} from "../api/agentMarkup";

export const addAgentMarkup = payload => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.ADD_MARKUP_PRICE
    });
    addMarkupPriceAPI(payload)
      .then(res => {
        dispatch({
          type: types.ADD_MARKUP_PRICE_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.ADD_MARKUP_PRICE_FAILED
        });
        return reject(err);
      });
  });

export const sendAddedMarkupTicket = (payload, key) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.SEND_MARKUP_TICKET
    });
    sendAddedMarkupTicketAPI(payload, key)
      .then(res => {
        dispatch({
          type: types.SEND_MARKUP_TICKET_SUCCESS,
          payload: res
        });

        return resolve(res);
      })
      .catch(err => {
        dispatch({
          type: types.SEND_MARKUP_TICKET_FAILED
        });
        return reject(err);
      });
  });

